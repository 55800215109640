import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode";

export const login = async (login_str, password) => {
    const {data} = await $host.post('api/user/login', {login: login_str, password});
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth');
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}